/**
 * External dependencies.
 */
import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card'
import CardMedia from '@material-ui/core/CardMedia'
import CardContent from '@material-ui/core/CardContent'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import { Skeleton } from '@mui/material';

import { getIconByType } from 'MyUtils';

const useStyles = makeStyles(theme => createStyles({
	card: {
		borderRadius: '32px',
		overflow: 'hidden',
		boxShadow: '-8px -16px 24px rgba(227, 230, 236, 0.5), 16px 8px 24px rgba(227, 230, 236, 0.5)',
		marginBottom: '20px',
	},
	cardContent: {
		padding: '29px 38px 31px !important'
	},
	cardTitle: {
		fontWeight: '600',
		whiteSpace: 'nowrap',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		marginBottom: '9px',
	},
	cardSubtitle: {
		fontWeight: '500',
		color: '#808191',
		fontSize: '16px',
		marginBottom: '18px',
		'& i': {
			marginRight: '10px'
		}
	},
	cardButton: {
		maxWidth: '122px',
		padding: '6px 10px',
	},
	skeletonImage: {
		height: '186px !important' 
	}
}));


const ArticleDef = ( { image, title, type, info, url, skeleton, openModal } ) => {
	const classes = useStyles();
	if (skeleton) {
		return (
			<Card className={classes.card}>
				<Skeleton 
					animation='wave'
					variant='rectangular'
					height="186"
					className={classes.skeletonImage}
				/>
	
				<CardContent className={classes.cardContent}>
					<Skeleton animation='wave'>
						<Typography className={classes.cardTitle} variant="h4" component="h4">{title}</Typography>
					</Skeleton>

					<Skeleton animation='wave'>
						<Typography className={classes.cardSubtitle} variant="h6" component="h6">
							{getIconByType(type)}
		
							{info}
						</Typography>
					</Skeleton>
					<Skeleton animation='wave'>
						<Button className={classes.cardButton} variant="outlined" color="secondary" onClick={openModal}>Editar</Button>
					</Skeleton>
				</CardContent>
			</Card>
		);
	} else {
		return (
			<Card className={classes.card}>
				<CardMedia
					component="img"
					height="186"
					image={image}
					alt={title}
				/>
	
				<CardContent className={classes.cardContent}>
					<Typography className={classes.cardTitle} variant="h4" component="h4">{title}</Typography>
					
					<Typography className={classes.cardSubtitle} variant="h6" component="h6">
						{getIconByType(type)}
	
						{info}
					</Typography>
					
					<Button className={classes.cardButton} variant="outlined" color="secondary" onClick={openModal}>Editar</Button>
				</CardContent>
			</Card>
		);

	}
};

export default ArticleDef;