/**
 * External dependencies.
 */
import React, { useState } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import AvatarGroup from '@mui/material/AvatarGroup';
import Modal from '@mui/material/Modal';
import { Skeleton } from '@mui/material';

/**
 * Internal dependencies.
 */
import ModalEdit from 'components/modal-edit/modal-edit';
import ModalGuests from 'components/modal-guests/modal-guests';

const useStyles = makeStyles(theme => createStyles({
	article: {
		position: 'relative',
		marginBottom: '32px',
		background: '#fff',
		boxShadow: '-8px -16px 24px rgba(227, 230, 236, 0.5), 16px 8px 24px rgba(227, 230, 236, 0.5)',
		borderRadius: '16px',
		display: 'flex',
		alignItems: 'center',
		padding: '7px 40px',
		[theme.breakpoints.down('sm')]: {
			padding: '7px 20px'
		},
		[theme.breakpoints.down('xs')]: {
			padding: '0',
			boxShadow: 'none',
			background: 'none',
			marginBottom: '15px',
		}
	},
	articleActions: {
		position: 'absolute',
		top: '50%',
		right: '63px',
		transform: 'translateY(-50%)',
		[theme.breakpoints.down('lg')]: {
			right: '40px',
		},
		[theme.breakpoints.down('sm')]: {
			right: '20px',
		},
		[theme.breakpoints.down('xs')]: {
			right: '0',
		},
		'& button': {
			fontSize: '18px',
			color: '#00CCEE',
			letterSpacing: '0.5px',
			marginLeft: '78px',
			background: 'none !important',
			[theme.breakpoints.down('lg')]: {
				marginLeft: '40px',
			},
			[theme.breakpoints.down('sm')]: {
				marginLeft: '10px',
			},
			[theme.breakpoints.down('xs')]: {
				marginLeft: '7px',
				fontSize: '0',
				minWidth: '0',
				padding: '0',
			},
			'& i': {
				display: 'none',
				[theme.breakpoints.down('xs')]: {
					display: 'inline-block',
					margin: '0'
				}
			}
		}
	},
	articleImage: {
		marginRight: '21px',
		[theme.breakpoints.down('xs')]: {
			width: '37px',
			marginRight: '11px',
		}
	},
	articleAvatars: {
		marginLeft: '32px',
		[theme.breakpoints.down('xs')]: {
			marginLeft: '19px',
		},
		'& .MuiAvatar-root': {
			marginLeft: '-12px !important',
			[theme.breakpoints.down('xs')]: {
				marginLeft: '-10px !important',
				width: '24px',
				height: '24px',
				color: '#808191',
				fontSize: '12px',
			}
		}
	},
	articleAvatar: {
		width: '40px',
		height: '40px',
		fontSize: '20px',
		[theme.breakpoints.down('xs')]: {
			fontSize: '12px',
			width: '24px',
			height: '24px'
		}
	},
	skeletonImage: {
		height: '62px !important',
		width: '62px !important' 
	},
	skeletonAvatar: {
		height: '40px !important',
		width: '40px !important',
		fontSize: '20px',
		[theme.breakpoints.down('xs')]: {
			fontSize: '12px',
			width: '24px',
			height: '24px'
		},
		backgroundColor: 'rgb(227, 227, 227) !important'
	},
	skeletonActions: {
		position: 'absolute',
		top: '50%',
		right: '63px',
		transform: 'translateY(-50%)',
		display: 'flex'
	}
}));


const Article = ( { image, name, avatars, skeleton, roomId } ) => {
	const classes = useStyles();
	const [openModal, setOpenModal] = useState(false);
	const [openModalGuests, setOpenModalGuests] = useState(false);

	if (skeleton) {
		return (
			<Box className={classes.article}>
				<Skeleton animation='wave' variant='circular' height="62" width="62"
					className={classes.skeletonImage + ' ' + classes.articleImage}>
				</Skeleton>

				<Skeleton animation='wave' width='10em'><Typography variant="h5" component="h5">{name}</Typography></Skeleton>
	
				{/* <AvatarGroup max={4} className={classes.articleAvatars}>
					<Avatar 
						key={1}
						className={classes.articleAvatar}
					>
						<Skeleton variant='circular' animation='wave' className={classes.skeletonAvatar}></Skeleton>
					</Avatar>
					<Avatar 
						key={1}
						className={classes.articleAvatar}
					>
						<Skeleton variant='circular' animation='wave' className={classes.skeletonAvatar}></Skeleton>
					</Avatar>
				</AvatarGroup> */}
	
				<Box className={classes.skeletonActions}>
					<Skeleton animation='wave'>
						<Button variant="text" onClick={(e) => { setOpenModalGuests(!openModalGuests) }}>
							Invitados
		
							<i className="ico-pen"></i>
						</Button>
					</Skeleton>
					<Skeleton animation='wave' style={{marginLeft: '40px'}}>
						<Button variant="text" onClick={(e) => { setOpenModal(!openModal) }}>
							Editar círculo
		
							<i className="ico-pen"></i>
						</Button>
					</Skeleton>
				</Box>	
			</Box>
		);	
	} else {
		return (
			<Box className={classes.article}>
				<Box className={classes.articleImage}>
					<img src={image} alt="" />
				</Box>
	
				<Typography variant="h5" component="h5">{name}</Typography>
	
				{/* <AvatarGroup max={4} className={classes.articleAvatars}>
					{avatars?.map((avatar, index) => {
						if ( !avatar.image.length ) {
							return (
								<Avatar 
									key={index}
									className={classes.articleAvatar}
								>
									{avatar.name.split(' ')[0][0].toUpperCase() + avatar.name.split(' ')[1][0].toUpperCase()}
								</Avatar>
							)
						}
	
						return (
							<Avatar
								key={index}
								className={classes.articleAvatar}
								alt={avatar.name}
								src={avatar.image}
							/>
						) 
					})}
				</AvatarGroup> */}
	
				<Box className={classes.articleActions}>
					<Button variant="text" onClick={(e) => { setOpenModalGuests(!openModalGuests) }}>
						Invitados
	
						<i className="ico-person"></i>
					</Button>
	
					<Button variant="text" onClick={(e) => { setOpenModal(!openModal) }}>
						Editar círculo
	
						<i className="ico-pen"></i>
					</Button>
				</Box>	
	
				<Modal
					open={openModal}
					onClose={(e) => { setOpenModal(false) }}
				>
					<div><ModalEdit circlename={name} roomId={roomId} onClose={(e) => { setOpenModal(false) }} /></div>
				</Modal>

				<Modal
					open={openModalGuests}
					onClose={(e) => { setOpenModalGuests(false) }}
				>
					<div><ModalGuests onClose={(e) => { setOpenModalGuests(false) }} roomId={roomId}/></div>
				</Modal>
			</Box>
		);	
	}

};

export default Article;